// Dependencies
import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
// Hooks
// import useShare from "../hooks/useShare";
import useMicrocopy from "../hooks/useMicrocopy";
// Context
// ...
// Components
import ArtistList from "../components/ArtistList";
import Artwork from "../components/Artwork";
import Layout from "../components/Layout";
import TrackList from "../components/TrackList";
import TrackListMap from "../components/TrackListMap";
import RichText from "../components/RichText";

const ArtistTemplate = ({ data, pageContext: { locale } }) => {

  // Content
	const { name, track, artwork, bio } = data.contentfulTrackArtist
	const artists = data.allContentfulTrackArtist.nodes
  const { aboutTheArtist, viewArtist, moreArtists } = useMicrocopy(locale)

	const titles = () => (
		<p>
			{aboutTheArtist} <br />
			<strong>{name}</strong> 
		</p>
	)

  return (
    <Layout
      title={`${name} | MSCTY.SPACE`}
      headerTitles={titles}
			locale={locale}
      shareImage={null}
      colours={{
        primary: '#FFFFFF',
        secondary: '#0B0B0A'
      }}
      isPage={false}
    >
      <div className="listen-page">
        <div className="track-view">
          <div className="track-view__left">
						{artwork ? (
							<div className="artwork--fill">
								<Artwork {...artwork} />
							</div>
							//<TrackViewArtwork artwork={artwork} />
						) : (
							<TrackListMap tracks={track} locale={locale} />
						)}
          </div>
          <div className="track-view__right">
            <div className="track-list">
							{bio && (
								<div className="p4 has-links artist-bio">
									<RichText content={bio} />
								</div>
							)}
              {track && <TrackList tracks={track} locale={locale} />}
            </div>
          </div>
        </div>
				<ArtistList title={moreArtists} viewArtist={viewArtist} artists={artists} />
      </div>
			
    </Layout>
  )
}

ArtistTemplate.propTypes = {
  data: PropTypes.object.isRequired
}

export default ArtistTemplate

export const ArtistTemplatePageQuery = graphql`
  query ArtistTemplateQuery($slug: String!, $locale: String!) {
    contentfulTrackArtist(
			slug: { eq: $slug }, 
			node_locale: { eq: $locale }
		) {
      name
			track {
				...ContentfulTrackFragment
			}
			artwork {
				...ContentfulArtworkFragment
			}
			headerTitles {
				raw
			}
			bio {
				raw
			}
    }
		## All artists
		allContentfulTrackArtist(
			filter: {slug: {ne: null}, node_locale: {eq: $locale}}
			sort: {fields: name, order: ASC}
		) {
			nodes {
				name
				slug
				track {
					slug
				}
				artwork {
					...ContentfulArtworkFragment
				}
			}
		}
  }
`
